* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  touch-action: manipulation;
}
body {float: left;width: 100%;margin: 0;padding: 0;box-sizing: border-box;position: relative;overflow: hidden;background-color: #f2f2f2 !important;font-family: 'poppinsregular';}
.wrapper {float: left;width: 100%;}
/* html {scrollbar-color: #f1f1f1 #ccc;scrollbar-width: thin;}
html:hover {
scrollbar-color: #888 #555;
} */
.profilUser h4 {
  font-size: 15px;
  margin: 0px;
}
.loginMainblog.Mainregisterproduct {
  margin: 0 auto;
  float: none;
  display: table;
}
.profilUser small {
  text-transform: capitalize;
  font-size: 13px;
}
.profilUser {
  float: left;
  width: auto;
  line-height: 16px;
  text-align: left;
}
.searchInfo {
position: absolute;
top: 9px;
left: 7px;
background-color: #fff;
font-size: 13px;
cursor: pointer;
}
.framumberWraps {
  float: left;
  width: 850%;
  position: relative;
}
.clearbtn {
float: right;
width: 13% !important;
color: #fff;
background-color: springgreen !important;
border: 1px solid springgreen !important;
border-radius: 4px;
font-size: 14px;
height: 41px;
color: #004dac !important;
}
.framumberWraps {
  float: left;
  width: 85%;
  position: relative;
}

.framumberWraps .frame-number-icon {
  top: 8px;
}

.framumberWraps .frame-number-loader {
  position: absolute;
  top: 11px;
  right: 0;
}

.framumberWraps .frame-number-loader .spinner-border {
  top: 0;
  border-width: 3px;
}
.framumberWraps .frame-number-icon {
  top: 8px;
}
#registerbtn {
  padding: 10px 30px !important;
  width: 100%;
}
.MainregisterproductWrapper {
  float: left;
  width: 100%;
  position: relative;
  height: 100dvh;
  display: block !important;
}
.MainregisterproductWrapper .logoheader img {
  width: 245px;
}
.formloginset .mb-3 {
  margin-bottom: 12px !important;
}

/* Targeting WebKit-based browsers */
.Bigform_custom_scroll::-webkit-scrollbar {
width: 4px; /* Width of the scrollbar */
}

.Bigform_custom_scroll::-webkit-scrollbar-track {
background-color: #f1f1f1; /* Track color */
}

.Bigform_custom_scroll::-webkit-scrollbar-thumb {
background-color: #888; /* Thumb color */
border-radius: 6px; /* Rounded corners for the thumb */
}

.Bigform_custom_scroll::-webkit-scrollbar-thumb:hover {
background-color: #555; /* Thumb color on hover */
}

:root {
  --loginColor: #0E0E30;
  --maincolor: #0e39b6;
}

/* sidebar css */
.sidebarWrapper {float: left;width: 301px;display: flex;height: calc(100vh - 58px);border-right: 3px solid #fff;position: relative;}
.sidebarWrapper::after {content: "";float: left;width: 1px;height: 100%;background-color: #ddd;position: absolute;right: -3px;top: 0;}
.sidebarMain {float: left;width: 50px;background-color: #0E0E30;}
.sidebarMain .flex-column {overflow: hidden;}
.sidebarMain .nav-link {
padding: 13px 10px;
float: left;
width: 100%;
text-align: center;
cursor: pointer;
position: relative;
display: flex;
align-items: center;
justify-content: center;
font-size: 15px;
color: #fff;
}
.sidebarMain .nav-link i {
line-height: normal;
}
.sidebarMain .nav-link img {
object-fit: contain;
object-position: center;
}
.sidebarMain .nav-link:hover, 
.sidebarMain .nav-link.active {
color: #34b739;
}

.sidebarMain .nav-link:hover::after,
.sidebarMain .nav-link.active::after {
content: "";
float: left;
width: 2px;
height: 100%;
background-color: #34b739;
position: absolute;
left: 0;
top: 0;
}
.sidebarMain .nav-link .active {
display: none;
}
.sidebarMain .nav-link:hover .inactive, 
.sidebarMain .nav-link.active .inactive {
display: none;
}
.sidebarMain .nav-link:hover .active,
.sidebarMain .nav-link.active .active  {
display: block;
}
.head_section {
float: left;
width: 100%;
background-color: #fff;
border-bottom: 1px solid #ddd;
height: 58px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0px 15px 0px 0px;
}
.head_section a.nav-logo {
float: left;
width: auto;
}
.head_section a.nav-logo img {
width: 150px;
}
.dropdownHeader {
float: right;
width: auto;
}
.dropdownHeader .dropdown i{
margin-right: 4px;
}
.dropdownHeader .dropdown .dropdown-toggle {
padding: 0px;
background: transparent !important;
color: #333 !important;
border: 0px;
border-radius: 0px;
line-height: normal;
box-shadow: none !important;
outline: none !important;
display: flex;
align-items: center;
column-gap: 8px;
}
.sidebarWrapper .sidebar {
float: left;
width: calc(100% - 50px);
height: 100%;
background-color: #fff;
}
.sidebarWrapper .sidebar .flex-column .nav-link {
padding: 14px 12px;
color: #333;
font-size: 14px;
line-height: normal;
display: flex;
column-gap: 10px;
align-items: center;
justify-content: flex-start;
position: relative;
}
.sidebarWrapper .sidebar .flex-column .nav-link span {
float: left;
width: auto;
position: absolute;
right: 10px;
top: 15px;
font-size: 12px;
}
.sidebarWrapper .sidebar .flex-column .nav-link.active, 
.sidebarWrapper .sidebar .flex-column .nav-link:hover {
background-color: #34b739;
color: #fff;
}
.collapnseDrop  {
background-color: #eee;
border-bottom: 1px solid #eee;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul {
padding: 0px;
margin: 0px;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li {
float: left;
width: 100%;
font-size: 14px;
list-style: none;
}
.navLogo {
float: left;
border-right: 1px solid #ddd;
height: 100%;
padding: 10px;
display: flex;
align-items: center;
justify-content: flex-start;
}
.pageTitle {
float: left;
width: auto;
text-transform: capitalize;
flex: 1;
font-size: 15px;
margin: 0px;
padding-left: 20px;
}
/* sidebar css */

/* login page css start here */
.loginWrapper {
float: left;
width: 100%;
position: relative;
/* height: 100vh; */
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
overflow: auto;
}
.MainLoginScreen {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.ovarlay {
  float: left;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}
.verifyotpheader {
  float: left;
  width: 100%;
  text-align: left;
}
.verifyotpheader p {
  font-weight: 400 !important;
  line-height: 25px;
  margin: 0px 0px 30px !important;
  text-align: left !important;
}
.sendOpt_btn .spinner-border {
  height: 20px;
  width: 20px;
  border-width: 3px;
}
.sendOpt_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 9px;
}
.verifyotpheader h4 {
  margin: 0 0 18px;
}
.MainLoginScreen .logoheader {
  z-index: 9;
  position: relative;
}
.MainLoginScreen .loginMainblog.Mainregisterproduct {
  z-index: 99;
  position: relative;
  color: #fff;
}
.MainLoginScreen .formloginset .form-label {
  color: rgb(255, 255, 255);
}
.MainLoginScreen .formloginset input {
  background-color: #fff;
  color: #000;
}
.registerbtn .spinner-border {
  width: 23px;
  height: 23px;
  border-width: 3px;
}
.back_btn.back_btn_transfernt {
  background-color: #fff !important;
  border-color: #fff !important;
  border-radius: 8px;
  padding: 12.5px 10px;
  margin: 12px 0 0;
  width: 100%;
  position: relative;
  color: #333;
  font-weight: 500;
}
.back_btn {
  background: transparent;
  color: #ffff;
  border: 0px;
  width: 100%;
  padding: 10px 10px;
  font-size: 16px;
  margin: 7px 0 0;
  outline: none !important;
  box-shadow: none !important;
}
.header_logout {
  float: left;
  width: auto;
  position: fixed;
  right: 32px;
  font-size: 26px;
  top: 24px;
  background-color: #004DAC;
  color: #fff;
  box-sizing: border-box;
  line-height: normal;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 9;
}
.backto_permission {
  float: left;
  width: auto;
  position: absolute;
  right: 32px;
  font-size: 26px;
  top: 24px;
  background-color: #004DAC;
  color: #fff;
  box-sizing: border-box;
  line-height: normal;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  margin: 0px;
  border: 0px;
}
.tabmobile_buttons {
  float: left;
  width: 100%;
  display: flex;
  column-gap: 15px;
}
.tabmobile_buttons .mb-3 {
  width: auto;
}
.header_logout .bi-power {
  line-height: normal;
}
.franhcise_header {
  float: left;
  width: 100%;
  position: relative;
  margin: 0 0 20px;
  border-bottom: 2px solid #d9d9d9;
}
.logoheader {
  font-size: 39px;
  font-weight: 700;
  letter-spacing: .8px;
  margin: 0px;
  padding: 20px 10px;
  text-align: center;
  width: 100%;
}
.logoheader img {
  width: 100px;
}
.loginMainblog {
float: left;
width: 400px;
}
.loginMainblog h4 {
float: left;
width: 100%;
font-size: 20px;
margin: 0 0 21px;
font-family: 'poppinsmedium';
color: #fff;
}
.mainloginwrps {
float: left;
width: 100%;
height: 100dvh;
}
.mb-3 {
float: left;
width: 100%;
}
.formloginset input {
  border: 1px solid #aaa !important;
  border-radius: 8px;
  height: 48px;
  padding: 14px 12px;
  box-shadow: none !important;
  font-size: 14px;
}
.widthfullbtn {
width: 100%;
}
.formloginset select {
width: 100%;
border-radius: 0px;
height: 38px;
font-size: 13px;
padding: 10px 10px;
border-color: #ced4da;
outline: none;
box-shadow: none !important;
background-image: url("../img/caret-down.svg");
background-size: 10px;
background-repeat: no-repeat;
background-position: 96% center;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
}
small.note {
font-size: 13px;
margin: 5px 0 0;
color: #7a7a7a;
}
.formloginset .btn {
background-color: #004DAC !important;
border-color: #004DAC !important;
border-radius: 8px;
padding: 8px 10px;
margin: 5px 0 0;
width: 100%;
position: relative;
color: #fff !important;
overflow: hidden;
z-index: 9;
}
body .btn.button-1{
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  border-radius: 0px;
  padding: 8px 10px;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 9;
  color: white!important;
}
body .btn.button-1.btn-sm {
  font-size: 14px !important;
  border-radius: 0px !important;
  padding: 6.6px 10px !important;
}
body .btn.button-1:focus{
  background-color: #7f878f!important;
}
.signInbtn::after {float: left;width: 100%;height: 100%;position: absolute;left: 0;top: 0;z-index: -1;content: "";background-color: #0E0E30;border-color: #0E0E30;transform: translateY(100%);-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;}
.signInbtn:hover::after {transform: translateY(0%);-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;}
.passwordfiled {
float: left;
width: 100%;
position: relative;
}
#searchbtn {
  margin: 0px;
}
.passwordfiled input {
padding-right: 35px;
}
.passwordfiled .showpassbtn {
width: 18px;
height: 18px;
position: absolute;
right: 9px;
top: 8px;
cursor: pointer;
}
.passwordfiled .showpassbtn svg {
float: left;
width: 100%;
}
.danger-colorset {
font-size: 12px;
color: #F84646;
float: left;
width: 100%;
margin: 4px 0 0;
}
/* login page css end here */

/* pannel css */
.panelwrapper {
float: left;
width: calc(100% - 301px);
height: calc(100dvh - 58px);
padding: 10px;
position: relative;
}
.panelContentwrapper {
float: left;
width: 100%;
height: 100%;
background-color: #fff;
padding: 10px;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a {
float: left;
width: 100%;
text-decoration: none;
color: #333;
padding: 13px 15px 13px 35px;
font-size: 13px;
position: relative;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a:hover,
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a.active {
background-color: #ccc;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a:after {
content: "o";
float: left;
font-weight: 500;
left: 14px;
position: absolute;
top: 12px;
width: auto;
}
#button-tooltip {
padding: 0px !important;
}
/* pannel css */

/* table filter */
.headerbtngroups {
float: left;
width: auto;
align-items: center;
display: flex;
}
.export_btncmn {
font-size: 14px;
padding: 6px 14px;
margin: 0 0 5px;
position: relative;
cursor: pointer;
display: flex;
align-items: center;
background-color: var(--loginColor) !important;
color: #fff !important;
margin-left: 10px;
}
.export_btncmn:hover {
background-color: #2769ff !important;
}
.graphs_columns {
float: left;
width: 100%;
position: relative;
margin: 5px 0 25px;
}
.productQuantyset {float: left;width: auto;display: flex;align-items: center;border: 1px solid #ddd;border-radius: 10px;overflow: hidden;background-color: #eee;max-width: 130px;margin: -5px 0 0;}
.cart_wrappperr li .cartbtn_wraps .quantity_product .productQuantyset {width: 100%;display: flex;justify-content: space-between;background: transparent;border: 0px;font-size: 18px;}
.productQuantyset .btn {padding: 5px 5px;outline: none !important;border: 0px !important;margin: 0px 5px;}
.productQuantyset .btn i {color: #6e6c6c;}
.productQuantyBox {float: left;width: auto;word-break: break-all;}
.redmednstar {color: red;font-size: 19px;line-height: 10px;display: inline-block;position: relative;top: 3px;}
.addQuantity i {color: var(--loginColor) !important;}
.graphs_columns h4 {float: left;width: 100%;font-size: 17px;margin: 0 0 20px;}
.piechartwraps {float: left;}
.card-header-New {float: left;margin: 0 0 15px;width: 100%;}
.card-header-New select {height: 36px !important;border-radius: 0px !important;font-size: 13px;}
.searchBoxwithbtn {float: left;position: relative;width: 260px;}
.searchBoxwithbtn .form-control {border: 1px solid #ddd;border-radius: 0;box-shadow: none!important;font-size: 13px;height: 36px;padding: 7px 35px 7px 27px;}
.searchBoxwithbtn.search .form-control{padding-right: 55px;}
.searchBoxwithbtn .form-control:not( ~ .searchbtn ){padding-right: 0px!important;}
.searchBoxwithbtn .searchbtn, .searchBoxwithbtn .searchbtn:focus {
position: absolute;
right: 0px;
top: 0px;
border-radius: 0px;
height: 100%;
width: 32px;
padding: 0px;
display: flex;
align-items: center;
justify-content: center;
background-color: #34b739;
border-color: #34b739;
}
.searchBoxwithbtn .searchbtn:hover {
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
}
.searchBoxwithbtn .icon-tabler-search {
width: 19px;
}
.serachBoxWIth .searchbtn, .serachBoxWIth .searchbtn:focus {
position: absolute;
right: 0px;
top: 0px;
border-radius: 0px;
height: 100%;
width: 32px;
padding: 0px;
display: flex;
align-items: center;
justify-content: center;
background-color: #004DAC !important;
border-color: #004DAC !important;
margin: 0 0 0 !important;
}
.serachBoxWIth .searchbtn:hover {
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
}
.serachBoxWIth .searchbtn:hover .icon-tabler-search {
  color: #fff !important;
}
.serachBoxWIth .icon-tabler-search {
width: 19px;
}
.card-header-right {float: right;width: auto;}
.activestatusselet, .userLimitselect {background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);background-position: 91%;background-repeat: no-repeat;background-size: 13px;border: 1px solid #ddd!important;border-radius: 0;cursor: pointer;float: left;font-size: 14px;height: 36px;width: 90px !important;}
.card-header-right select:first-child {margin-right: 10px;}
.card-header-right select:nth-child(2) {margin-right: 10px;}
.card-header-right select {box-shadow: none!important;}
.head_cmn_btn {font-size: 14px !important;border-radius: 0px !important;padding: 6.6px 10px !important;background-color: #0E0E30 !important;color: #fff !important;border-color: var(--loginColor) !important;}
.head_cmn_btn:hover {background-color: #333 !important;}
.head_reset_btn, .head_reset_btn:focus, .head_reset_btn:active {font-size: 14px;border-radius: 0px;margin: 0 0 0 10px;padding: 6.6px 10px;background-color: red !important;color: #fff !important;border-color: red !important;}
.head_reset_btn:hover {background-color: red !important;}
.cmn_export_btn, .cmn_export_btn:focus, .cmn_export_btn:active {font-size: 14px;border-radius: 0px;margin: 0 0 0 10px;padding: 6.6px 10px;background-color: #00b13a !important;color: #fff !important;border-color: #00b13a !important;}
.cmn_export_btn:hover {background-color: #008d21 !important;}
.cmn_export_btn img {width: 18px;line-height: normal;margin: 0px 3px 0px 0px;}
.head_cmn_btn i {margin: 0 4px 0 0px;}
.head_reset_btn i {margin: 0 4px 0 0px;}
.searchBoxwithbtn .searchclear {
float: right;
width: auto;
position: absolute;
top: 9px;
right: 40px;
font-size: 13px;
cursor: pointer;
color: red;
background-color: #fff;
}
/* table filter */

/* table css  */
.tableView {float: left;width: 100%;position: relative;}
.tableView thead tr th {font-size: 13px;font-weight: 400;padding: 10px 10px;background-color: #f6f6f6;color: #212529;}
.tableView tbody {border-top: 0px !important;}
.tableView tbody tr td {font-size: 13px;vertical-align: middle;color: #212529;}
.AvtarMain {float: left;width: auto;border-radius: 0.375rem;border: 1px solid #eee;overflow: hidden;}
.AvtarMain img {width: 60px;}
.table-hover>tbody>tr:hover>* {background-color: transparent !important;--bs-table-accent-bg: none !important;--bs-table-bg-state: transparent !important;}
.badge {
font-size: 12px !important;
font-weight: 500 !important;
letter-spacing: 0.5px !important;
padding: 8px 10px !important;
}
.badge.bg-success {
background-color: #eaf7ec !important;
color: #2aa13d !important;
}
.badge.bg-danger {
background-color: #fbebeb !important;
color: #dc3545 !important;
}
.edittable  {
border-radius: 0px !important;
padding: 0px !important;
line-height: normal !important;
font-size: 17px !important;
box-shadow: none !important;
background-color: transparent !important;
color: #000 !important;
border: 0px !important;
outline: none !important;
}
.edittable i {
line-height: normal;
}
.exportbtn {
background-color: #34b739 !important;
color: #fff !important;
font-size: 14px !important;
border-radius: 0px !important;
padding: 6.6px 10px !important;
margin-right: 10px;
}
.exportbtn i {
margin: 0 4px 0 0px;
}
/* table css  */

/* common toggle css */
.comnToggle {float: left;width: auto;}
.comnToggle i {font-size: 25px;cursor: pointer;line-height: normal;}
.comnToggle .bi-toggle2-on {color: #008fff;}
/* common toggle css */

/* form css */
.tabsWrappers {
float: none;
width: 100%;
max-width: 75%;
margin: 15px auto 0;
display: table;
padding: 0px 60px;
position: relative;
border-top: 0px;
border-bottom: 0px;
}
.formloginset .form-label {
float: left;
width: 100%;
font-size: 15px;
padding-left: 7px;
margin: 0 0 5px;
}
.mb-3 {
float: left;
width: 100%;
}
.formloginset textarea {
border-radius: 0px;
height: 120px;
font-size: 13px;
box-shadow: none !important;
resize: none;
}
.formloginset .form-control[type=file] {
line-height: 29px;
}
.widthfullbtn {
width: 100%;
}
.formloginset select {
width: 100%;
border-radius: 0px;
height: 38px;
font-size: 13px;
padding: 6px 10px;
border-color: #ced4da;
outline: none;
box-shadow: none !important;
background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);
background-size: 10px;
background-repeat: no-repeat;
background-position: 96% center;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
}
.tablecollapsewraps {
background-color: transparent !important;
}
.tablecollapsewraps table th {
background-color: #f8f8f8;
font-size: 12px;
padding: 10px 12px;
font-weight: 500;
}
.tablecollapsewraps table td {
font-size: 12px;
}
.formData {
float: left;
width: 100%;
font-size: 14px;
}
.formsearchList {
float: left;
width: 100%;
border-bottom: 1px solid #eee;
margin-bottom: 12px;
}
.formbtn {
background-color: #34b739 !important;
border-color: #34b739 !important; 
border-radius: 0px !important;
padding: 8px 25px !important;
margin: 5px 0 0 !important;
position: relative;
overflow: hidden;
z-index: 9;
}
.ToggleMainWrap {
display: flex;
float: left;
width: 100%;
align-items: center;
justify-content: flex-start;
column-gap: 10px;
}
.formloginset {
float: left;
width: 100%;
position: relative;
}
.formloginset .HeaderDivider:nth-last-child(2) {
margin: 19px 0 18px;
}
.HeaderDivider {
float: left;
width: 100%;
padding: 30px 15px 10px 15px;
border: 1px solid #eee;
margin: 19px 0 30px;
position: relative;
}
.HeaderDivider h6.subtitleForm {
float: left;
width: auto;
position: absolute;
background-color: #f9fafb;
padding: 10px 15px;
font-size: 14px;
top: -18px;
left: 15px;
margin: 0px;
border: 1px solid #eee;
}
.btnGroupswraps {
float: left;
width: 100%;
display: flex;
column-gap: 10px;
margin-bottom: 10px;
}
.btnGroupswraps .Resetbtn {
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
border-radius: 0px !important;
padding: 8px 10px !important;
margin: 5px 0 0 !important;
position: relative;
overflow: hidden;
z-index: 9; 
width: auto !important;
}
#GetDetailsbntn {
margin-top: 0px !important;
}
.filtr_Form {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}
.datepickField {
float: left;
width: 100%;
position: relative;
}
.datepickField input {
border-radius: 0px;
height: 38px;
width: 100%;
font-size: 13px;
box-shadow: none !important;
border: 1px solid #dee2e6 !important;
padding: .375rem .75rem;
outline: none !important;
background-image: url("../img/calendar.png");
background-repeat: no-repeat;
background-size: 16px;
background-position: 90% center;
}
.datepickField.min input {
background-position: 96% center;
}
.Addbtn {
background-color: #34b739 !important;
border-color: #34b739 !important;
border-radius: 0px !important;
padding: 8px 10px !important;
margin: 5px 0 0 !important;
position: relative;
overflow: hidden;
z-index: 9;
width: auto !important;
}
.react-datepicker-wrapper {
width: 100%;
}
.card-header-New .formloginset input {
height: 36px !important;
}
.totaltd th {
background-color: #f6f6f6 !important;
color: #212529 !important;
font-size: 13px;
font-weight: 500;
padding: 10px 10px !important;
}

.selectMultiselect .godown-select__input-container.css-qbdosj-Input {
margin: 0px;
padding-bottom: 0px;
padding-top: 0px;
}
.selectMultiselect .godown-select__value-container.css-1fdsijx-ValueContainer {
padding: 0px 8px !important;
}
.selectMultiselect .godown-select__control.css-13cymwt-control, .selectMultiselect .css-t3ipsp-control {
border-color: #AAAAAA !important;
border-radius: 0px;
outline: none !important;
box-shadow: none !important;
min-height: 48px !important;
border-radius: 8px;
}
.selectMultiselect .css-13cymwt-control, .selectMultiselect .css-1jqq78o-placeholder, .selectMultiselect .css-1dimb5e-singleValue {
font-size: 14px !important;
}
.formloginset #selectMultiselectBrand input{
height: 28px !important;
border: 0px !important;
}
.formloginset .whloginhbtn{
  width:100%;
  padding:5px !important;
  margin:0px !important
}
/* form css */

/* image upload  */
.uploadfielsbuttons .uploadfilebuttons {margin-bottom: 17px;}
.imageuploads {float: left;width: 100px;height: 100px;border-radius: 20px;position: relative;border: 1px solid #ddd;}
.imageuploads img {float: left;width: 100%;height: 100%;border-radius: 20px;object-fit: cover;object-position: center;}
.imageuploads .closeIcon {float: left;width: 17px;height: 17px;position: absolute;right: -2px;top: -2px;cursor: pointer;background-color: #fff;border-radius: 100%;}
.imageuploads .closeIcon::after {content: "";float: left;height: 100%;width: 100%;-webkit-mask-image: url("../img/closebutton.png"); mask-image: url("../img/closebutton.png"); mask-position: center; -webkit-mask-position: center; mask-repeat: no-repeat; -webkit-mask-repeat: no-repeat; mask-size: contain; -webkit-mask-size: contain; background-color: red !important;}

.pdffileviewo .closeIcon {float: left;width: 17px;height: 17px;position: absolute;right: -7px;top: -3px;cursor: pointer;background-color: #fff;border-radius: 100%;}
.pdffileviewo .closeIcon::after {content: "";float: left;height: 100%;width: 100%;-webkit-mask-image: url("../img/closebutton.png"); mask-image: url("../img/closebutton.png"); mask-position: center; -webkit-mask-position: center; mask-repeat: no-repeat; -webkit-mask-repeat: no-repeat; mask-size: contain; -webkit-mask-size: contain; background-color: red !important;}

.fileloader {float: left;width: 28px;height: 28px;position: absolute;right: -6px;background-color: #fff;border-radius: 100%;top: -5px;padding: 5px;box-shadow: 0px 0px 17px -3px #ccc;}
.fileloader img {float: left;width: 100%;height: 100%;object-fit: contain;object-position: center;}
.fileloader.active {padding: 0px;}
.uploadbuttons {float: left;width: 100px;height: 100px;position: relative;cursor: pointer;border: 1px solid #eee;border-radius: 20px;}
.uploadbuttons .uploadfilebox img {float: left;height: 100%;width: 100%; border-radius: 20px;}
.uploadfilebox img {float: left;height: 100%;width: 100%; border-radius: 20px;object-fit: cover;object-position: top;}
.uploadbuttons input.uploadfilInput {position: absolute;visibility: hidden;height: 100%;width: 100%;left: 0;top: 0;}
.uploadbuttons .fileloader label {float: left;width: 100%;height: 100%;cursor: pointer;}
.uploadbuttons .fileloader label img {float: left;width: 100%;height: 100%;object-fit: contain;object-position: center;}
.error_medotory {
float: left;
width: 100%;
font-size: 12px;
color: #fff;
/* background-color: #dc3545; */
background: rgba(220, 53, 69, 0.7);
padding: 15px 10px 9px 13px;
border-radius: 8px;
margin: -9px 0 0;
z-index: -9;
position: relative;
line-height: normal;
display: flex;
align-items: flex-start;
column-gap: 6px;
justify-content: flex-start;
}
.error_medotory.error_medotory_range {
padding: 9px 10px 9px 10px;
margin: 5px 0 0px;
position: relative;
}
.error_medotory.error_medotory_range::before {
  content: "\F235";
  font-family: 'bootstrap-icons';
  position: absolute;
  top: -11px;
  left: 11px;
  font-size: 15px;
  opacity: 0.7;
  color: #dc3545;
}
.uploadfilebox {float: left;width: 100%;height: 100%;}
/* image upload  */

/* side content css */
.MainHeader {
float: left;
width: 100%;
margin-bottom: 15px;
padding-bottom: 7px;
text-align: right;
border-bottom: 1px solid #ddd;
}
.MainHeader .backbtn {
padding: 0px 10px;
font-size: 13px;
background: transparent !important;
box-shadow: none !important;
text-decoration: none;
color: #333;
}
.FranchiseWallet .backbtn {
padding: 0px 10px;
font-size: 13px;
background: transparent !important;
box-shadow: none !important;
text-decoration: none;
color: #333;
}
.selectMultiselect {
float: left;
width: 100%;
position: relative;
}
.searchWrapper {
min-height: 12px !important;
padding: 3px 10px !important;
border-radius: 0px !important;
border: 1px solid #ddd !important;
}
.mendatory_star {
}
.multiSelectContainer {
background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);
background-position: 95%;
background-repeat: no-repeat;
background-size: 13px;
}
.multiSelectContainer input {
height: 33px !important;
margin-top: 0px !important;
}
.selectMultiselect.removeBackground .multiSelectContainer {
background-image: none !important;
}
.chip {
padding: 3px 9px !important;
}
.optionListContainer {
z-index: 99 !important;
}
.MainHeader_Tab {
float: left;
width: auto;
padding: 0px;
margin: 0px;
display: flex;
align-items: center;
column-gap: 15px;
}
.MainHeader_Tab li {
list-style: none;
font-size: 15px;
cursor: pointer;
font-weight: 500;
position: relative;
}
.MainHeader_Tab li:hover, .MainHeader_Tab li.active {
color: #34b739;
}
.MainHeader_Tab li:hover::after, .MainHeader_Tab li.active::after {
content: "";
float: left;
width: 100%;
height: 3px;
background-color: #34b739;
position: absolute;
left: 0;
bottom: -11px;
}
/* side content css */

.signInbtn .spinner-border {
height: 21px;
width: 21px;
border-width: 3px;
}
.commonModal .notformsection {
text-align: center;
line-height: 28px;
font-size: 17px;
}
.commonModal .notformsection .logoutIconbtn {
font-size: 25px;
color: red;
}
.commonModal .modal-dialog .modal-content {
border-radius: 0px !important;
}
.commonModal .modal-dialog .modal-footer .btn-secondary {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
}
.commonModal .modal-dialog .modal-footer .addbtncmn  {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
background-color: #004DAC !important;
}
.commonModal .modal-dialog .modal-content .modal-header .btn-close {
box-shadow: none !important;
outline: none !important;
}

.OrderDetailsModal .notformsection {
text-align: center;
line-height: 28px;
font-size: 17px;
}
.OrderDetailsModal .notformsection .logoutIconbtn {
font-size: 25px;
color: red;
}
.OrderDetailsModal .modal-dialog .modal-content {
border-radius: 0px !important;
}
.OrderDetailsModal .modal-dialog .modal-footer .btn-secondary {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
}
.OrderDetailsModal .modal-dialog .modal-footer .addbtncmn  {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
background-color: #34b739 !important;
}
.OrderDetailsModal .modal-dialog .modal-content .modal-header .btn-close {
box-shadow: none !important;
outline: none !important;
}
.OrderDetailsModal .modal-dialog {
max-width: 65%;
}

.errorDetailsModal .notformsection {
text-align: center;
line-height: 28px;
font-size: 17px;
}
.errorDetailsModal .notformsection .logoutIconbtn {
font-size: 25px;
color: red;
}
.errorDetailsModal .modal-dialog .modal-content {
border-radius: 0px !important;
}
.errorDetailsModal .modal-dialog .modal-footer .btn-secondary {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
}
.errorDetailsModal .modal-dialog .modal-footer .addbtncmn  {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
background-color: #34b739 !important;
}
.errorDetailsModal .modal-dialog .modal-content .modal-header .btn-close {
box-shadow: none !important;
outline: none !important;
}
#GetDetailsbntn.filterBlackbtn{
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
border-radius: 0px !important;
padding: 7.5px 10px !important;
margin: 0px 0 0 10px !important;
position: relative;
overflow: hidden;
z-index: 9;
width: auto !important;
}
/* pagination css start here */
.paginationCustom {float: left;width: 100%;padding: 0px;margin: 9px 0px 0px;}
.paginationCustom li:first-child button, .paginationCustom li:last-child button,
.paginationCustom li:nth-child(2) button, .paginationCustom li:nth-last-child(2) button {
  color: transparent;
  position: relative;
}
.paginationCustom li:first-child button::before {
  content: "\F22D";
  font-family: 'bootstrap-icons';
  color: #333;
  opacity: 1;
  font-size: 17px;
  position: absolute;
  left: 6px;
  line-height: normal;
}
.paginationCustom li:nth-child(2) button::before {
  content: "\F129";
  font-family: 'bootstrap-icons';
  color: #333;
  opacity: 1;
  font-size: 20px;
  position: absolute;
  left: 5px;
  line-height: normal;
}
.paginationCustom li:last-child button::before {
  content: "\F133";
  font-family: 'bootstrap-icons';
  color: #333;
  opacity: 1;
  font-size: 21px;
  position: absolute;
  left: 0;
  line-height: normal;
}
.paginationCustom li:nth-last-child(2) button::before {
  content: "\F133";
  font-family: 'bootstrap-icons';
  color: #333;
  opacity: 1;
  font-size: 20px;
  position: absolute;
  right: 5px;
  line-height: normal;
}
.paginationCustom li:last-child button::before {
  content: "\F231";
  font-family: 'bootstrap-icons';
  color: #333;
  opacity: 1;
  font-size: 17px;
  position: absolute;
  right: 0;
  line-height: normal;
}
.paginationCustom li .rpb-item--active {background-color: #34b739; color: #fff !important;}
.paginationCustom li button {width: 29px;height: 29px;min-width: 29px;font-size: 14px;border-radius: 100%;}
/* pagination css end here */

.godown-select__menu {z-index: 9999 !important;}
.Bigform_custom_scroll {
float: left;
width: 100%;
overflow-x: hidden;
overflow-y: auto;
padding-right: 5px;
}
.selectMultiselectCategory .godown-select__input {
height: 39px !important;
}
.godown-select-container .selectMultiselectBrand .godown-select__input {
height: 40px !important;
}
#selectMultiselectBrand .css-16xfy0z-control {
  border-radius: 0px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}

.tableView tbody .btn-link {
padding: 0px;
font-size: 13px;
background: transparent !important;
}
.tableView tbody .btn-link-color {
  color: #0d6efd !important;
  cursor: pointer;
  font-weight: 400;
}
#activestatusselet {
width: 100% !important;
}
#FranchiseUserReportWidth {
width: 130px !important;
}
.commonModal.addBoxModalNew .modal-dialog {
  max-width: 680px;
}
#FranchiseUserReportWidthNew {
  width: 230px !important;
}
.newsumbitbnt  {
background-color: #34b739 !important;
color: #fff !important;
font-size: 14px !important;
border-radius: 0px !important;
padding: 6.6px 10px !important;
margin-right: 10px;
}
.table-description {
height: 90px;
width: 180px !important;
border-radius: 0px !important;
box-shadow: none !important;
}
.cancelBtn {
background-color: #34b739 !important;
color: #fff !important;
font-size: 14px !important;
border-radius: 0px !important;
padding: 6.6px 10px !important;
}
.tableBolanchor {
color: #00f;
cursor: pointer;
}
.ScrollbarsSidebar .view {
margin-right: -17px !important;
padding-right: 4px;
overflow-x: hidden !important;
margin-bottom: 0px !important;
}
.sotklevelbtn {
float: right;
position: relative;
right: 20px;
}
.serachBoxWIth {
float: left;
width: 100%;
position: relative;
border-radius: 8px;
overflow: hidden;
}
.serachBoxWIth .spinner-border {
position: absolute;
right: 40px;
top: 13px;
height: 20px;
width: 20px;
border-width: 3px;
}
.defaultvalueField {
float: left;
width: 100%;
border: 1px solid #ddd;
height: 40px;
display: flex;
align-items: center;
padding: 10px;
font-size: 13px;
cursor: no-drop;
background-color: #eee;
color: #333;
}
.defaultvalueFieldaddress {
float: left;
width: 100%;
border: 1px solid #ddd;
padding: 10px;
font-size: 13px;
cursor: no-drop;
background-color: #eee;
}
.defaultvalueFieldaddress ul {
padding-left: 15px;
margin-bottom: 0px;
}
.defaultvalueFieldaddress ul li {
margin-bottom: 3px;
font-size: 13px;
}
.defaultvalueFieldaddress ul li:last-child {
margin-bottom: 0px;
}
.pincode_error {
float: left;
width: auto;
position: absolute;
right: 42px;
top: 12px;
cursor: pointer;
}
.filedinfomsg {
font-size: 12px;
float: right;
cursor: pointer;
}
.UploadCmnBTN {
position: relative;
}
.UploadCmnBTN input[type="file"] {
position: absolute;
left: 0;
top: 0;
visibility: hidden;
}
.leftFormheader {
float: left;
width: inherit;
}
#UploadCmnBTN {
background-color: #0e0e30!important;
border-color: #0e0e30!important;
border-color: var(--loginColor)!important;
border-radius: 0!important;
color: #fff!important;
font-size: 14px!important;
padding: 6.6px 10px!important;
}
.UploadLoader {
float: left;
width: auto;
margin: 5px 0px 0px 10px;
}
.UploadLoader .spinner-border {
height: 23px;
width: 23px;
border-width: 4px;
}
.uplaodedFilename {
float: left;
width: auto;
padding: 7px 20px 7px 34px;
font-size: 14px;
word-break: break-word;
background-color: #eee;
border-radius: 30px;
border: 1px solid #ddd;
display: flex;
align-items: center;
position: relative;
}
.exsting_newdetails {
float: left;
width: 100%;
margin: 15px 0 0;
}
.exsting_newdetails h4 {font-size: 15px;margin: 0 0 12px;}
.uplaodedFilename i:first-child {
font-size: 20px;
position: absolute;
left: 8px;
}
.uplaodedFilename .bi-x-circle-fill {
color: red;
position: absolute;
right: -5px;
top: 8px;
z-index: 99;
background-color: #fff;
line-height: normal;
border-radius: 100%;
font-size: 18px;
cursor: pointer;
height: 19px;
width: 19px;
}
.uplaodedFilename .bi-x-circle-fill::before {
vertical-align: baseline;
}
.ScrollbarsSidebarHide .formloginset {
padding: 0px 15px 0px 0px;
}
.btn.disabled {
cursor: no-drop !important;
}
.actionbtn {
float: left;
width: 17px;
height: 17px;
padding: 0px !important;
border: 0px !important;
outline: none !important;
box-shadow: none !important;
background: transparent !important;
-webkit-transition: 0.2s;
-moz-transition: 0.2s;
-ms-transition: 0.2s;
-o-transition: 0.2s;
transition: 0.2s;
}
.actionbtn img {
float: left;
height: 100%;
width: 100%;
}
.actionbtn.active img {
transform: rotate(180deg);
-webkit-transition: 0.2s;
-moz-transition: 0.2s;
-ms-transition: 0.2s;
-o-transition: 0.2s;
transition: 0.2s;
}
.panelContentwrapper .table tbody .actionbtn {
margin-right: 12px;
}
.panelContentwrapper .table tbody .actionbtn:last-child {
margin-right: 0px;
}
.table-bordered>:not(caption)>* {
border-color: #dee2e6 !important;
}
.tablecollapsewraps .table {
margin-bottom: 0px !important;
}
.datepickField .react-datepicker__close-icon {
right: 38px;
}
.datepickField .react-datepicker__close-icon::after {
background-color: red;
}
.react-datepicker .react-datepicker__month-select {
height: auto !important;
padding: 1px 5px;
border-radius: 8px;
}
.react-datepicker .react-datepicker__year-select {
height: auto !important;
padding: 1px 7px;
border-radius: 8px;
width: 70px;
}
#mb-3 {
margin-bottom: 7px !important;
}
.filtercollapsebtn {
background-color: #0E0E30;
color: #fff;
font-size: 14px;
padding: 6px 9px;
}
#stocklevelExportbtn {
width: auto;
margin: 0px 7px 0px;
padding: 7.5px 10px !important;
}
#PurchaseOrderbtn {
  width: auto;
  margin: 0px;
  padding:7.5px 25px !important;
}
#PurchaseOrderClearbtn {
  width: auto;
  margin: 0px;
  background-color: #0E0E30 !important;
  padding:7.5px 15px !important;
}
#userLimitselect {
width: 85px !important;
}
.notformsection ul {
text-align: left;
margin: 0px;
padding: 10px 10px;
background-color: #eee;
border-radius: 10px;
}
.notformsection ul li {
font-size: 16px;
padding-bottom: 9px;
display: flex;
align-items: center;
justify-content: center;
column-gap: 9px;
}
.notformsection ul li:last-child {
padding-bottom: 0px;
}
.notformsection ul li span {
float: left;
width: 24px;
height: 24px;
background-color: #333;
color: #fff;
display: flex;
align-items: center;
text-align: center;
justify-content: center;
border-radius: 100%;
font-size: 14px;
}
.QauntityManage {
float: left;
width: auto;
display: flex;
align-items: center;
column-gap: 4px;
background-color: #eee;
border-radius: 7px;
padding: 2px 5px;
}
.QauntityManage .btn {
padding: 4px 5px;
outline: none !important;
box-shadow: none !important;
border: 0px !important;
font-size: 15px;
}
.QauntityManage h2 {
font-size: 15px;
line-height: normal;
margin: 0px;
}
.QauntityManage .remove .bi {color: red;}
.QauntityManage .add .bi {color: #34b739;}
.GSTAmounttotal {
float: left;
width: 100%;
padding-left: 17px !important;
margin: 0px;
padding: 8px 0px;
border-top: 1px solid #ddd;
border-bottom: 1px solid #ddd;
margin-bottom: 10px;
}
.GSTAmounttotal li {
float: left;
width: 100%;
padding: 2px 0px;
font-size: 14px;
color: #333;
}
.GSTAmounttotal li label {
font-weight: 500;
float: left;
}
.FranchiseWallet {
float: left;
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px 0px;
border-bottom: 1px solid #ddd;
margin-bottom: 12px;
font-size: 15px;
}
.FranchiseWallet .Franchise_name {
padding: 2px 0px;
font-size: 14px;
color: #333;
display: flex;
column-gap: 10px;
}
.FranchiseWallet .Franchise_name label {font-weight: 500;}
.FranchiseWallet .FranchiseBlance_name {
font-size: 14px;
color: #333;
display: flex;
column-gap: 10px;
background-color: #f5f5f5;
padding: 6px 15px;
border-radius: 20px;
}
.FranchiseWallet .FranchiseBlance_name label {font-weight: 500;}
.GSTAmounttotal textarea.form-control {
border-radius: 0px;
box-shadow: none !important;
width: 300px;
float: left;
font-size: 13px;
padding: 8px 10px;
height: 95px;
}
.PurchaseOrderCheckbox .form-check {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.PurchaseOrderCheckbox .form-check-input[type=checkbox] {
height: 17px;
width: 17px;
}
.Purchase_Order_Mendetory .godown-select__placeholder::after {
  content: "*";
  color: red;
}
.pdffileviewo {
float: left;
width: 100%;
padding: 6px 23px 6px 14px;
font-size: 14px;
border: 1px solid #ddd;
background-color: #f9f9f9;
border-radius: 7px;
position: relative;
display: flex;
column-gap: 8px;
word-break: break-word;
}
.smallLabelData {
float: left;
width: 100%;
padding: 8px 10px;
border: 1px solid #ddd;
font-size: 13px;
position: relative;
height: 38px;
cursor: no-drop;
}
.smallLabelData small {
float: left;
width: auto;
background-color: #fff;
position: absolute;
left: 3px;
top: -8px;
padding: 0px 8px;
font-size: 11px !important;
}
.smallLabelData div {
display: inline-block;
width: 100%;
white-space: nowrap;
overflow: hidden !important;
text-overflow: ellipsis;
}
#backbtbackgt {
background-color: #0E0E30 !important;
}
.smallLabelData .clearbtn {
position: absolute;
right: -6px;
top: -7px;
line-height: normal;
background-color: #fff;
border-radius: 100%;
cursor: pointer;
}
.smallLabelData .clearbtn i {
color: red;
}
#FranchiseUserReport .userLimitselect {
  margin-right: 10px;
}
.notinternetFoundPage {
  float: left;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255,255,255,0.5);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.notinternetFoundPage .connectwrapp {
  float: left;
  width: 540px;
  padding: 35px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.notinternetFoundPage .connectwrapp h2 {
  font-weight: 600;
  margin: 0 0 12px;
}
.notinternetFoundPage .connectwrapp .btn {
  background-color: #34b739 !important;
  border-radius: 0px;
  border-color: #34b739 !important;
  padding: 9px 20px;
  font-size: 16px;
}
.notinternetFoundPage .connectwrapp .bi-wifi-off {
  font-size: 40px;
  color: red;
}
.formloginsetStaticData .form-label {
  margin: 0 0 5px !important;
}
.formloginsetStaticData .mb-3 {
  margin-bottom: 10px !important;
}
.qntyClass {
  border-radius: 0px;
  box-shadow: none;
  width: 110px !important;
}
.account-table{border-spacing: 2px;    border-collapse: collapse;print-color-adjust: exact;font-size: 13px;    color: #495057;margin: 0px!important;}
.account-table> tr>td, .account-table> tr>th {border: 1px solid #000000;border-right: 1px solid #000000;}
.account-table> tr:last-child>td, .account-table> tr:last-child>th{border-bottom: 1px solid #000000;}
.account-table> tr:first-child>td, .account-table> tr:first-child>th{border-top: 1px solid #000000;}
.account-table> tr>td, .account-table tr>td, .account-table tr>th{padding: 5px;}
.account-table h5{font-size: 14px;}
.account-table h2{font-size: 18px;}
.account-table hr{ margin: 0 -1px;padding: 0px!important;}
.account-table h5,
.account-table h2,
.account-table p{margin: 0px;padding: 0px!important;}
.account-table .bt-0{border-top-width: 0px!important;}
.account-table .bl-0{border-left-width: 0px!important;}
.account-table .bb-0{border-bottom-width: 0px!important;}
.account-table .br-0{border-right-width: 0px!important;}
.account-table .logo .text-center{width: calc(100% - 360px);}
.account-table .tax-invoice{
  background: #10b068;
    color: #fff;
    padding: 10px 26px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 18px;
    display: inline-block;
}
.t-body-nowrap .carrier.min{min-width: 140px;}
.t-body-nowrap .carrier{white-space: nowrap;min-width: 170px;}
.table-common tr>th{font-size: 13px!important;}
.table-common tr>td{font-size: 13px!important;}
span.light-green{
  background-color: #eaf7ec !important;
  color: #2aa13d;
  font-weight: 500;
}
span.light-orange{
  background-color: #ffc107 !important;
  color: white;
  font-weight: 500;
}
span.light-red{
  background-color: #dc3545 !important;
  color: white;
  font-weight: 500;
}
span.light-grey{
  background-color: #acadb1 !important;
  color: white;
  font-weight: 500;
}
.btn.btn-icon{
  height: 35px;
  width: 35px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  outline: 0px!important;
  border-color: transparent!important;
  box-shadow: none!important;
}
.btn.btn-icon::after{display: none;}
.btn.btn-icon i{font-size: 19px;}
.modal-invoice .btn.btn-icon{position: absolute;right: 50px;}
.invoice_model{
  -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-color-adjust: exact;
    color-adjust: exact;
}
.invoice-details-common img{object-fit: contain;}
.createshortBox {
  padding: 0px !important;
  font-size: 27px !important;
  line-height: normal !important;
  outline: none !important;
  border: 0px !important;
}
.addBoxbtns {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  color: #fff !important;
  padding: 6px 15px !important;
  font-size: 15px !important;
  border-radius: 0px !important;
}
.deleteItemBtn {
  color: red;
  font-size: 15px;
  cursor: pointer;
}
#addBoxbtns {
  margin: 0px;
  width: auto;
}
.selectBoxAnchor {
  float: right;
  font-size: 14px;
  text-decoration: underline;
  color: #004DAC;
  cursor: pointer;
}
.createshortBox i {
  line-height: normal;
  color: #34b739;
}
@media print {
  .account-table{border-spacing: 2px;    border-collapse: collapse;print-color-adjust: exact;font-size: 13px;    color: #495057;margin: 0px!important;}
  .account-table tr>td, .account-table tr>th {border: 1px solid #000000!important;}
}

.commonLoaderwarap {
  float: left;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.6);
  z-index: 999999;
  color: #fff;
  column-gap: 10px;
}
.loginMainblog.Mainregisterproduct .formloginset input {
  height: 48px;
  border-radius: 8px;
  border: 1px solid #AAAAAA;
  padding: 14px 12px 14px 12px;
}
.loginMainblog.Mainregisterproduct .formloginset input:disabled {
  background-color: #fff !important;
  cursor: no-drop;
}
.loginMainblog.Mainregisterproduct .formloginset input.react-datepicker-ignore-onclickoutside {
  border: 1px solid #aaa !important;
  outline: none !important;
  box-shadow: none !important;
}
.loginMainblog.Mainregisterproduct .formloginset .defaultvalueField {
  height: 48px;
  border-radius: 8px;
  border: 1px solid #AAAAAA;
  padding: 14px 12px 14px 12px;
  background-color: #fff;
}
.welcome_screen {
  float: left;
  width: 100%;
  text-align: center;
  color: #fff;
  margin: 0px 0 25px;
}
.welcome_screen h4 {
  font-size: 33px;
  font-weight: 400;
  margin: 3px 0 20px;
}
p.welcome_screen_pass {
  font-size: 19px;
}
.welcomerCenterImage {
  float: left;
  width: 100%;
  position: relative;
  margin: 0 0 20px;
}
.welcomerCenterImage img {
  width: 240px;
}
.welcomerCenterImage p {
  float: left;
  width: 100%;
  font-size: 20px;
  margin: 30px 0 6px;
}
.registerbtn  {
  background-color: springgreen !important;
  color: #004dac !important;
  padding: 10px 110px !important;
  font-size: 18px !important;  
  font-weight: 500 !important;
}
.uplaodBoxFileadd {
  display: flex;
  align-items: center;
  float: left;
  width: 100%;
  column-gap: 15px;
}
.uplaodBoxFileadd .registerbtn {
  font-size: 15px !important;
    padding: 10px 14px !important;
    display: flex;
    white-space: pre;
    border: 0px;
    border-radius: 6px
}

.frame-number-loader .spinner-border{
  position: absolute;
  right: 11px;
  top: 38px;
  height: 20px;
  width: 20px;
}
.image_name .closeIcon {padding: 2px;cursor: pointer;background-color: #fff;border-radius: 100%;}
.frame-number-icon{
    position: absolute;
    right: 13px;
    top: 35px;
}
.backlinkbtn {
  color: #fff !important;
  font-size: 14px;
}
.uploadfilewithupload {
  float: left;
  width: 100%;
  position: relative;
}
.uploadfilewithupload input {
  padding: 2px 12px !important;
  line-height: 45px !important;
}
.uploadfilewithupload .spinner-border {
  position: absolute;
  right: 13px;
  top: 13px;
  height: 20px;
  width: 20px;
  border-width: 3px;
}
.dateYearcolumn {
  float: left;
  width: 100%;
  /* display: flex;
  column-gap: 10px; */
}
.dateYearcolumn input[type="text"] {
  width: 100%;
}
.radio_buttons_selections_custom [type="checkbox"]:checked,
.radio_buttons_selections_custom [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.radio_buttons_selections_custom [type="checkbox"]:checked + label,
.radio_buttons_selections_custom [type="checkbox"]:not(:checked) + label
{
  position: absolute;
  padding-left: 0px;
  cursor: pointer;
  line-height: 14px;
  display: inline-block;
  color: #666;
  left: 0;
  right: 0;
  width: 14px;
  float: none;
  margin: 0 auto;
  z-index: 99;
}
.radio_buttons_selections_custom [type="checkbox"]:checked + label:before,
.radio_buttons_selections_custom [type="checkbox"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 14px;
  height: 14px;
  border: 1px solid #AAA;
  border-radius: 100%;
  background: #AAA;
  background-image: url("../img/tick-circle.svg");
  background-size: 18px;
  background-position: center;
}
.radio_buttons_selections_custom [type="checkbox"]:checked + label:after,
.radio_buttons_selections_custom [type="checkbox"]:not(:checked) + label:after {
  content: '';
  width: 14px;
  height: 14px;
  background: #004DAC;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background-image: url("../img/tick-circle.svg");
  background-size: 18px;
  background-position: center;
}
.radio_buttons_selections_custom [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.radio_buttons_selections_custom [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.radio_buttons_selections_custom [type="checkbox"] {
  visibility: hidden;
  height: auto !important;
  position: absolute;
  left: 0;
  top: 0;
}

.radio_buttons_selections_custom {
  float: left;
  width: 100%;
  display: flex;
}
.radio_buttons_selections_custom .radio_wrapper {
  float: left;
  width: 10%;
  position: relative;
  text-align: center;
  height: 49px;
}
.radio_buttons_selections_custom .radio_wrapper::before {
  content: "";
  float: left;
  width: 50%;
  height: 3px;
  background: #AAA;
  position: absolute;
  left: 0;
  bottom: 17px;
  z-index: -9;
}
.radio_buttons_selections_custom .radio_wrapper::after {
  content: "";
  float: left;
  width: 50%;
  height: 3px;
  background: #AAA;
  position: absolute;
  right: 0;
  bottom: 17px;
  z-index: -9;
}
.radio_buttons_selections_custom .radio_wrapper.active::before, .radio_buttons_selections_custom .radio_wrapper.active::after {
  background: #004DAC;
}
.radio_buttons_selections_custom .radio_wrapper:first-child::before, .radio_buttons_selections_custom .radio_wrapper:last-child::after {
  display: none;
}
.radio_custom {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
}
.radio_buttons_selections_wrap {
  float: left;
  width: 100%;
  position: relative;
}
.radio_buttons_selections_wrap .radio_buttons_selections {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 22px;
}
.radio_buttons_selections_wrap .radio_buttons_selections h5 {
  font-size: 16px;
  color: #fff;
  float: left;
  width: 100%;
  margin: 0 0 18px;
}
.radio_buttons_selections_custom .radio_wrapper span {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 13px;
  margin-bottom: 4px;
  text-decoration: none;
}
.upload_file_box_wraps {
  float: left;
  width: 48%;
}
.imageRefrance_wrapper .formloginset .upload_file_box_wraps:first-child {
  margin-right: 12px;
}
.error_medotory_upload_image {
  padding: 9px 9px 9px 9px;
  margin: 7px 0 0;
}
.error_medotory_upload_image::before {
  content: "\F235";
  font-family: 'bootstrap-icons';
  color: #dc3545;
  margin: 3px 0 0;
  position: absolute;
  right: 7px;
  top: -16px;
  font-size: 17px;
}
.error_message_boxes {
  float: left;
  width: 100%;
  position: relative;
}
.error_message_handle .alert-danger-wrapper:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.error_message_handle .alert-danger-wrapper:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.alert-danger-wrapper {
  float: left;
  width: 100%;
  font-size: 12px;
  color: #fff;
  /* background-color: #dc3545; */
  background: rgba(220, 53, 69, 0.7);
  padding: 9px 9px 9px 9px;
  position: relative;
}
.error_message_handle .alert-danger-wrapper:first-child::before {
  content: "\F235";
  font-family: 'bootstrap-icons';
  position: absolute;
  top: -14px;
  left: 11px;
  font-size: 15px;
  opacity: 0.7;
  color: #dc3545;
}
.upload_file_box {
  float: left;
  width: 100%;
  color: #fff;
  text-align: center;
  position: relative;
}
.uploadfiles {
  float: left;
  width: 100%;
  position: relative;
  color: #fff;
  border-radius: 8px;
  border: 1px solid #fff;
  padding: 16px 12px 16px 12px;
  overflow: hidden;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 6px;
}
.upload_file_box span {
  float: left;
  width: 100%;
  font-size: 13px;
  text-transform: capitalize;
  margin: 6px 0 0;
}

/* camera upload modal */
.capture_camera_modal {
  float: left;
  width: 600px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: 0 auto;
  text-align: center;
  padding: 13px 25px 25px 25px;
  background-color: #fff;
  border: 1px solid #aaa;
  transform: translate(-50%, -50%);
  z-index: 9999;
  /* height: calc(100vh - 25px); */
  overflow: auto;
}
.capture_camera_modal video {
  width: 100%;
}
.background_wrapper {
  float: left;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
}
.capture_camera_modal_header {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 13px;
}
.capture_camera_modal_header i {
  float: right;
  cursor: pointer;
  color: red;
  font-size: 29px;
  position: absolute;
  right: 25px;
  top: 5px;
}
.uploadfiles.uploadfilesuploaded {
  background-color: #fff;
  color: #004DAC;
  overflow: visible;
  cursor: default;
}
.uploadfiles.uploadfilesuploaded i {
  float: left;
  width: 19px;
  font-size: 19px;
  color: #004DAC;
  position: absolute;
  right: -8px;
  cursor: pointer;
  font-weight: 500;
  height: 19px;
  top: -6px;
  background-color: #fff;
  border-radius: 100%;
}
.uploadfiles.uploadfilesuploaded i::before {
  position: absolute;
  left: 0;
}
.uploadfiles.uploadfilesuploaded .uplaoded_file_name {
  float: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  text-align: center;
}
.uploadfiles.uploadfiles_video {
  padding: 11px 10px 11px 10px;
  text-align: left;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}
.uploadfiles.uploadfiles_video span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  text-align: left;
  float: left;
  width: 245px;
}
#uplaodvideo {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  position: relative;
  border: 1px solid #fff !important;
  background: transparent;
  background-color: transparent !important;
  justify-content: center;
  column-gap: 10px;
  padding: 14px 10px;
}
#uplaodvideo input {
  float: left;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
}
#uplaodvideo img {
  width: 30px;
}
#uplaodvideoNEW {
  width: 180px !important;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  row-gap: 6px;
  background-color: #aaa !important;
  background: #aaa !important;
  border-color: #aaa !important;
  padding: 14px 10px;
  position: relative;
}
#uplaodvideoNEW input {
  float: left;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
}
.Speedometer_wrapper {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
}
.Speedometer_wrapper .speedometer {
  height: 200px !important;
  color: #fff;
}
.speedometer .segment-value, .speedometer .current-value{
  color: #fff !important;
  fill: #fff !important;
  font-weight: 500 !important;
}
.speedometer .current-value {
  /* display: none; */
}
.loginMainblog.Mainregisterproduct h3 {
  text-align: center;
  font-size: 30px;
  float: left;
  width: 100%;
  font-weight: 600;
  margin: 20px 0 7px;
  color: #fff;
}
.loginMainblog.Mainregisterproduct  p {
  text-align: center;
  font-size: 17px;
  margin: 0 0 40px;
  color: #fff;
}
.bottom_header {
  text-align: center;
  font-size: 26px;
  float: left;
  width: 100%;
  font-weight: 600;
  margin: 15px 0 45px;
  color: #fff;
}
.Speedometer_wrapper .speedometer .current-value {
  font-size: 22px !important;
  transform: scale3d(1.5, 1.5, 1.5);
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  -ms-transform: scale3d(1.5, 1.5, 1.5);
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
}
.react-datepicker-popper {
  z-index: 99 !important;
}
#registerbackbtn {
  background-color: transparent !important;
  background: transparent;
  color: #004DAC !important;
  border: 0px;
  margin-top: 10px;
}
.dateYearcolumn.dateYearcolumnMonthsss .react-datepicker__header.react-datepicker-year-header, .dateYearcolumn.dateYearcolumnMonthsss .react-datepicker__navigation {
  display: none;
}
.rpoduct_offer_wrappers {
  float: left;
  width: 100%;
  position: relative;
  text-align: center;
}
.rpoduct_offer_wrappers h3 {
  font-size: 20px !important;
  margin: 0px 0 40px !important;
  line-height: 30px;
}
.tabkle_wrapper {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #fff;
  border-radius: 10px;
}
#tabkle_wrapper table tbody td, #tabkle_wrapper table thead th {
  border-color: #fff !important;
  border-bottom-width: 1px !important;
  font-size: 14px;
  padding: 12px 10px;
  color: #fff !important;
}
#tabkle_wrapper table tbody td:first-child, #tabkle_wrapper table thead th:first-child {
  border-right: 1px solid #fff;
}
#tabkle_wrapper table tbody tr:last-child td {
  border-bottom-width: 0px !important;
}
#tabkle_wrapper table {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 0px;
}
#tabkle_wrapper table thead {
  background: linear-gradient(to right, #00FF7F -2%, #004DAC);
  background: -webkit-linear-gradient(left, #00FF7F -2%, #004DAC); 
  background: -moz-linear-gradient(left, #00FF7F -2%, #004DAC); 
  background: -ms-linear-gradient(left, #00FF7F -2%, #004DAC); 
  background: -o-linear-gradient(left, #00FF7F -2%, #004DAC); 
}
#tabkle_wrapper table thead tr {
  display: table;
  width: 100%;
}
#tabkle_wrapper table thead tr th {
  background: transparent !important;
  width: 50%;
}
#tabkle_wrapper table tbody tr {
  display: table;
  width: 100%;
}
#tabkle_wrapper table tbody td {
  color: #fff !important;
  background: transparent !important;
  width: 50%;
}
#tabkle_wrapper table thead th {
  font-weight: 500;
  font-size: 14px;
}
.camera_wrappers {
  float: left;
  width: 100%;
  position: relative;
}
.camera_wrappers video {
  margin-bottom: 7px;
}
.camera_wrappers img {
  margin-bottom: 15px;
}
.capturebtn {
  background-color: #004DAC !important;
  border-color: #004DAC !important;
  color: #fff !important;
}
.camera_wrappers .btn {
  font-size: 15px;
  padding: 8px 15px;
}
.camera_wrappers .btn i {
  line-height: normal;
}
.deleteimage {
  border: 1px solid red !important;
  color: red !important;
  font-weight: 400;
}
.deleteImagebtn {
  border: 1px solid #000 !important;
  color: #000 !important;
  font-weight: 400;
}
.btn_group_manage {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}
.camera_loader {
  float: left;
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  position: absolute;
  right: 0;
  left: 0;
  top: 23px;
  text-align: center;
  justify-content: center;
}
.camera_loader .spinner-border {
  height: 23px;
  width: 23px;
  border-width: 3px;
}
.imageRefrance_wrapper {
  float: left;
  width: 100%;
  position: relative;
  border: 1px solid #ffffff;
  padding: 11px;
  border-radius: 8px;
  margin-bottom: 15px;
}
.imageRefrance_wrapper h4 {
  font-size: 14px;
  margin: 0 0 14px;
}
.iamge_boxed {
  float: left;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.iamge_boxed li {
  float: left;
  width: 30.33%;
  list-style: none;
  margin-right: 13px;
}
.iamge_boxed li img {
  float: left;
  width: 100%;
  height: 92px;
  object-fit: cover;
  border: 1px solid #ddd;
  object-position: center;
  border-radius: 8px !important;
  cursor: pointer;
}
.iamge_boxed li:last-child {
  margin-right: 0px;
}
.btn-groups-wrapper {
  display: flex;
  align-items: center;
}
.backbtnbtn#registerbtn {
  background: transparent !important;
  border: 0px !important;
  font-weight: 500;
}
.iamge_boxed li .rangeforimage {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 13px;
  margin: 5px 0 0;
  color: #fff;
}

/* preview image modal css */
.Image_preview_wrapper {
  float: left;
  width: 100%;
  height: calc(100dvh);
  position: fixed;
  margin: 0 auto;
  text-align: center;
  padding: 13px 25px 25px 25px;
  z-index: 9999;
  overflow: auto;
  left: 0;
  right: 0;
  color: #fff;
}
.preivew_image {
  float: left;
  width: 100%;
  height: 100%;
}
.preivew_image img {
  height: 100%;
}
.Image_preview_wrapper h4 {
  float: left;
  width: 100%;
  margin: 0 0 20px;
}
.Image_preview_wrapper i {
  float: right;
  cursor: pointer;
  color: #fff;
  font-size: 38px;
  position: absolute;
  right: 20px;
  top: 0px;
}
.otp_wrappper {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 8px;
}
.otp_wrappper input {
  text-align: center;
}
.verfy_Modal_wrapper {
  float: left;
  width: 360px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: #fff;
  padding: 33px 32px 36px;
  text-align: center;
}
.verfy_Modal_wrapper i {
  font-size: 70px;
  color: #004dac;
  line-height: 70px;
}
.verfy_Modal_wrapper h1 {
  margin: 14px 0px 11px;
  font-size: 27px;
  font-weight: 500;
}
.verfy_Modal_wrapper p {
  color: #0a0a0a;
}
.verify_background {
  float: left;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #004dac;
  opacity: 0.8;
  z-index: 99;
}
.verify_background_new {
  float: left;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-image: url("../img/new/X3_Product_Banner.png");
  background-size: cover;
  background-position: top;
  padding: 44px 20px 44px 70px;
  z-index: 99;
}
.main_form_Box.permision_Modal_wrapper {
  left: 0 !important;
  top: 0 !important;
  position: relative !important;
  transform: none !important;
}
.main_form_Box.permision_Modal_wrapper .headin {
  margin: 0 0 20px;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  color: #fff !important;
  text-align: left;
}
.main_form_Box.permision_Modal_wrapper .btn_groups_modal {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
}
#customerseller {
  background-color: #fff;
  color: #004dac;
  border-color: #fff;
}
.verfy_Modal_wrapper {
  float: left;
  width: 360px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: #fff;
  padding: 33px 32px 36px;
  text-align: center;
}
.verfy_Modal_wrapper i {
  font-size: 70px;
  color: #004dac;
  line-height: 70px;
}
.verfy_Modal_wrapper h1 {
  margin: 14px 0px 11px;
  font-size: 27px;
  font-weight: 500;
}
.verfy_Modal_wrapper .btn {
  width: 100%;
  font-size: 16px;
  padding: 10px 10px;
  background-color: #004dac;
  border-color: #004dac;
}
.main_form_Box.permision_Modal_wrapper .btn {
  width: 100%;
  font-size: 16px;
  padding: 10px 10px;
  background-color: #004dac;
  border-color: #004dac;
}
.main_form_Box.permision_Modal_wrapper .headin {
  margin: 0px 0px 20px;
  font-size: 23px;
  color: #004dac;
}
.main_form_Box.permision_Modal_wrapper .modal_header_seller {
  float: left;
  width: 100%;
  padding: 16px 10px;
  border-bottom: 2px solid #ddd;
  margin: 0 0 20px;
}
.main_form_Box.permision_Modal_wrapper .modal_header_seller img {
  width: 185px;
}
.main_form_Box.permision_Modal_wrapper {
  /* padding: 20px 32px 36px !important; */
  /* -webkit-backdrop-filter: blur(8px); */
  /* backdrop-filter: blur(8px); */
  background: rgba(255, 255, 255, 0.2);
  /* border-radius: 25px; */
  /* background: transparent !important; */
}

/* new css added by aniket */
.MainregisterproductWrapper {
  background-image: url("../img/new/X3_Product_Banner.png");
  background-size: cover;
  background-position: top;
  padding: 44px 20px 44px 70px;
}
.main_form_Box {
  float: left;
  width: 380px;
  padding: 15px 25px 20px 25px;
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 25px;
}
.formloginset .form-label {
  color: #fff;
}
.main_form_Box .loginMainblog {
  width: 100%;
}

/* bike condtions range css start here */
.range-container {
  float: left;
  width: 100%;
  margin: 0px auto 10px;
  position: relative;
}
#rangeInput {
  width: 100%;
  appearance: none;
  height: 12px;
  outline: none;
  transition: opacity 0.2s, background 0.2s;
  padding: 0px;
  border-radius: 18px;
  background: linear-gradient(to right, #00FF7F, #004DAC , transparent);
  border: 0px !important;
}
#rangeInput::-webkit-slider-thumb {
  -webkit-appearance: none; 
  appearance: none; 
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: #004DAC;
  border: 5px solid #fff;
  cursor: pointer;
}
#rangeInput::-moz-range-thumb {
  -webkit-appearance: none; 
  -moz-appeaerance: none; 
  appearance: none; 
  width: 15px;
  height: 15px;
  background: #004DAC;
  border-radius: 100%;
  border: 5px solid #fff;
  cursor: pointer;
}
#rangeInput.range-setps-1::-webkit-slider-thumb {
  margin-left: -9px;
}
#rangeInput.range-setps-1::-moz-range-thumb {
  margin-left: -9px !important;
}
#rangeInput.range-setps-2::-webkit-slider-thumb {
  margin-left: -8px;
}
#rangeInput.range-setps-2::-moz-range-thumb {
  margin-left: -8px !important;
}
#rangeInput.range-setps-3::-webkit-slider-thumb {
  margin-left: -8px;
}
#rangeInput.range-setps-3::-moz-range-thumb {
  margin-left: -8px !important;
}
#rangeInput.range-setps-4::-webkit-slider-thumb {
  margin-left: -6px;
}
#rangeInput.range-setps-4::-moz-range-thumb {
  margin-left: -6px !important;
}
#rangeInput.range-setps-5::-webkit-slider-thumb {
  margin-left: -5px;
}
#rangeInput.range-setps-5::-moz-range-thumb {
  margin-left: -5px !important;
}
#rangeInput.range-setps-9::-webkit-slider-thumb {
  margin-left: 2px;
}
#rangeInput.range-setps-9::-moz-range-thumb {
  margin-left: 2px !important;
}
#rangeInput.range-setps-10::-webkit-slider-thumb {
  margin-left: 5px;
}
#rangeInput.range-setps-10::-moz-range-thumb {
  margin-left: 5px !important;
} 
.range-numbers {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  text-decoration: none !important;
  outline: none !important;
}
.range-numbers div {
  font-size: 12px;
  color: #fff;
  text-decoration: none !important;
  border-bottom-width: 0px !important;
  outline: none !important;
}
/* bike condtions range css end here */


/* progress bar css start here */
@keyframes progress {
  0% { --percentage: 0; }
  100% { --percentage: var(--value); }
}

@property --percentage {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.Speedometer_wrapper [role="progressbar"] {
  --percentage: var(--value);
  --primary: #369;
  --secondary: #adf;
  --size: 300px;
  animation: progress 2s 0.5s forwards;
  -webkit-animation: progress 2s 0.5s forwards; /* Chrome, Safari, and Opera */
  -moz-animation: progress 2s 0.5s forwards;    /* Firefox */
  -ms-animation: progress 2s 0.5s forwards;     /* Internet Explorer */
  width: var(--size);
  aspect-ratio: 2 / 1;
  border-radius: 50% / 100% 100% 0 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0 auto;
}
.Speedometer_wrapper [role="progressbar"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(from 0.75turn at 50% 100%, #00FF7F, #004DAC calc(var(--percentage) * 1% / 2 + 0.1%), #fff calc(var(--percentage) * 1% / 2 + 0.1%));
  mask: radial-gradient(at 50% 100%, white 55%, transparent 55.5%);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(at 50% 100%, #0000 55%, #000 55.5%);
  -webkit-mask-mode: alpha;
}
.Speedometer_wrapper [role="progressbar"]::after {
  counter-reset: percentage var(--value);
  content: counter(percentage);
  font-size: 20px;
  color: #fff;
  visibility: hidden;
}
.Speedometer_wrapper .mainscore_title {
  color: #fff;
  font-family: 'poppinsbold';
  font-size: 50px;
  float: left;
  width: 100%;
  margin: -12px 0 0;
  text-decoration: none;
  outline: none;
}
.Speedometer_wrapper .labels {
  float: left;
  width: 66%;
  position: absolute;
  left: 0;
  top: 46px;
  right: 0;
  margin: 0 auto;
}
.Speedometer_wrapper .labels img {
  width: 100%;
}
.Speedometer_wrapper .labels ul{
  padding: 0px;
  color: #fff;
  margin: 0px;
  font-size: 12px;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  text-decoration: none !important;
  outline: none !important;
}
.Speedometer_wrapper .labels ul li{
  list-style: none;
  text-decoration: none !important;
  border-bottom: 0px !important;
  outline: none !important;
}
.Speedometer_wrapper .labels ul li:first-child {
  position: absolute;
  left: 18px;
  bottom: -10px;
}
.Speedometer_wrapper .labels ul li:first-child::after {
  content: "0";
}
.Speedometer_wrapper .labels ul li:nth-child(2) {
  position: absolute;
  left: 31px;
  top: 49px;
}
.Speedometer_wrapper .labels ul li:nth-child(2)::after {
  content: "20";
}
.Speedometer_wrapper .labels ul li:nth-child(3) {
  position: absolute;
  left: 75px;
  top: 18px;
}
.Speedometer_wrapper .labels ul li:nth-child(3)::after {
  content: "40";
}
.Speedometer_wrapper .labels ul li:nth-child(4) {
  position: absolute;
  left: 128px;
  top: 18px;
}
.Speedometer_wrapper .labels ul li:nth-child(4)::after {
  content: "60";
}
.Speedometer_wrapper .labels ul li:nth-child(5) {
  position: absolute;
  right: 34px;
  top: 46px;
}
.Speedometer_wrapper .labels ul li:nth-child(5)::after {
  content: "80";
}
.Speedometer_wrapper .labels ul li:last-child {
  position: absolute;
  right: 15px;
  bottom: -10px;
}
.Speedometer_wrapper .labels ul li:last-child::after {
  content: "100";
}
/* progress bar css end here */
.submited_form_group {
  text-align: center;
  flex-direction: column;
  color: #fff;
  font-size: 13px;
  margin: 0 0 10px !important;
  row-gap: 7px;
}

:root {
	--hue: 223;
	--bg: hsl(var(--hue),90%,90%);
	--fg: hsl(var(--hue),90%,10%);
	--primary: hsl(var(--hue),90%,50%);
	--trans-dur: 0.3s;
	/* font-size: calc(16px + (32 - 16) * (100vw - 320px) / (2560 - 320)); */
}
.cycle_common_Loader {
  float: left;
  width: 100%;
  z-index: 99999;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
  background-color: rgba(0,0,0,0.8);
  height: 100vh;
}
.cycle_common_Loader .cycle_common_Loader_blog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cycle_common_Loader h4 {
  text-align: center;
  line-height: 23px;
  font-size: 15px;
  margin: 16px 0 0;
  font-family: 'metropolismedium';
}
.cycle_common_Loader .bike {
	display: block;
	margin: auto;
	width: 6em;
	height: auto;
}
.cycle_common_Loader .bike__body,
.cycle_common_Loader .bike__front,
.cycle_common_Loader .bike__handlebars,
.cycle_common_Loader .bike__pedals,
.cycle_common_Loader .bike__pedals-spin,
.cycle_common_Loader .bike__seat,
.cycle_common_Loader .bike__spokes,
.cycle_common_Loader .bike__spokes-spin,
.cycle_common_Loader .bike__tire {
	animation: bikeBody 3s ease-in-out infinite;
	stroke: var(--primary);
	transition: stroke var(--trans-dur);
}
.cycle_common_Loader .bike__front {
	animation-name: bikeFront;
}
.cycle_common_Loader .bike__handlebars {
	animation-name: bikeHandlebars;
}
.cycle_common_Loader .bike__pedals {
	animation-name: bikePedals;
}
.cycle_common_Loader .bike__pedals-spin {
	animation-name: bikePedalsSpin;
}
.cycle_common_Loader .bike__seat {
	animation-name: bikeSeat;
}
.cycle_common_Loader .bike__spokes,
.cycle_common_Loader .bike__tire {
	stroke: currentColor;
}
.cycle_common_Loader .bike__spokes {
	animation-name: bikeSpokes;
}
.cycle_common_Loader .bike__spokes-spin {
	animation-name: bikeSpokesSpin;
}
.cycle_common_Loader .bike__tire {
	animation-name: bikeTire;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
	:root {
		--bg: hsl(var(--hue),90%,10%);
		--fg: hsl(var(--hue),90%,90%);
	}
}

/* Animations */
@keyframes bikeBody {
	from { stroke-dashoffset: 79; }
	33%,
	67% { stroke-dashoffset: 0; }
	to { stroke-dashoffset: -79; }
}
@keyframes bikeFront {
	from { stroke-dashoffset: 19; }
	33%,
	67% { stroke-dashoffset: 0; }
	to { stroke-dashoffset: -19; }
}
@keyframes bikeHandlebars {
	from { stroke-dashoffset: 10; }
	33%,
	67% { stroke-dashoffset: 0; }
	to { stroke-dashoffset: -10; }
}
@keyframes bikePedals {
	from {
		animation-timing-function: ease-in;
		stroke-dashoffset: -25.133;
	}
	33%,
	67% {
		animation-timing-function: ease-out;
		stroke-dashoffset: -21.991;
	}
	to {
		stroke-dashoffset: -25.133;
	}
}
@keyframes bikePedalsSpin {
	from { transform: rotate(0.1875turn); }
	to { transform: rotate(3.1875turn); }
}
@keyframes bikeSeat {
	from { stroke-dashoffset: 5; }
	33%,
	67% { stroke-dashoffset: 0; }
	to { stroke-dashoffset: -5; }
}
@keyframes bikeSpokes {
	from {
		animation-timing-function: ease-in;
		stroke-dashoffset: -31.416;
	}
	33%,
	67% {
		animation-timing-function: ease-out;
		stroke-dashoffset: -23.562;
	}
	to {
		stroke-dashoffset: -31.416;
	}
}
@keyframes bikeSpokesSpin {
	from { transform: rotate(0); }
	to { transform: rotate(3turn); }
}
@keyframes bikeTire {
	from {
		animation-timing-function: ease-in;
		stroke-dashoffset: 56.549;
		transform: rotate(0);
	}
	33% {
		stroke-dashoffset: 0;
		transform: rotate(0.33turn);
	}
	67% {
		animation-timing-function: ease-out;
		stroke-dashoffset: 0;
		transform: rotate(0.67turn);
	}
	to {
		stroke-dashoffset: -56.549;
		transform: rotate(1turn);
	}
}


/* pdf view css */
.image_preview_wrapper {
  float: none;
  /* width: 794px; */
  width: 210mm;
  overflow: auto;
  background-color: #fff;
  /* height: 100dvh; */
  font-family: 'metropolisregular';
  margin: 0 auto;
  display: block;
}
.bike_gallery_range {
  float: right;
  width: 51%;
}
.custom_progress_relative {
  float: left;
  width: 100%;
  position: relative;
  height: 4px;
  background-color: #fff;
  border-radius: 10px;
}
.custom_progress_relative_range {
  float: left;
  width: 100%;
  padding: 0px;
  margin: 0 0 5px;
  line-height: normal;
}
.custom_progress_relative_range li {
  float: left;
  width: 10%;
  list-style: none;
  font-size: 6px;
  line-height: normal;
  text-align: center;
}
.custom_progress_relative_range li:first-child {
  text-align: left;
  padding-left: 4px;
}
.custom_progress_relative_range li:last-child {
  text-align: right;
  padding-right: 4px;
}
.custom_progress_relative_fill {
  float: left;
  background-color: #FF5858;
  height: 100%;
  border-radius: 10px;
  position: relative;
}
.custom_progress_relative_fill::after {
  content: "";
  float: left;
  width: 10px;
  height: 10px;
  background-color: #FF5858;
  border-radius: 100%;
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  top: -3px;
  box-shadow: 0px 0px 6px -1px #ccc;
}
.custom_progress_relative_fill.first_range::after {
  right: -10px;
}
.speedtom_wrapper {
  float: left;
  width: 100%;
}
.Inspection_score_details {
  float: left;
  width: 100%;
  position: relative;
  border-left: 3px solid #004DAC;
  padding: 2px 0px 2px 10px;
  line-height: normal;
}
.Inspection_score_details h3 {
  float: left;
  width: 100%;
  font-family: 'metropolissemi_bold';
  margin: 0px 0px 8px;
  font-size: 16px;
}
.Inspection_score_details p {
  float: left;
  width: 100%;
  font-size: 13px;
  line-height: 16px;
  margin: 0px;
}
.header_section {
  float: left;
  width: 100%;
  padding: 27px 30px;
}
.header_section img {
  width: 150px;
}
.banner_section {
  float: left;
  width: 100%;
  text-align: center;
  padding: 20px 0 0;
}
.banner_section h2 {
  float: left;
  width: 100%;
  color: #004DAC;
  font-family: 'metropolissemi_bold_italic';
  line-height: 29px;
  font-size: 28px;
  margin: 0 0 22px;
}
.banner_section p {
  font-size: 17px;
  width: 79%;
  margin: 0 auto;
  display: table;
  color: #000;
  line-height: 21px;
}
.lets_scroll_devide {
  float: left;
  width: 100%;
  margin: 40px 0 20px;
  text-align: center;
  color: #004DAC;
  font-size: 23px;
  font-family: 'metropolissemi_bold_italic';
  line-height: normal;
  position: relative;
}
.lets_scroll_devide::before {
  content: "";
  float: left;
  width: 31%;
  height: 2px;
  background-color: #004dac;
  position: absolute;
  left: 0;
  top: 11px;
}
.lets_scroll_devide::after {
  content: "";
  float: left;
  width: 31%;
  height: 2px;
  background-color: #004dac;
  position: absolute;
  right: 0;
  top: 11px;
}
.main_container {
  float: none;
  width: 95%;
  margin: 0 auto;
  display: table;
  position: relative;
}
.cycle_check_section {
  float: left;
  width: 100%;
}
.cycle_check_section img {
  float: left;
  width: 100%;
  margin: 0 0 18px;
}
.cycle_check_section p {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 13px;
  line-height: 15px;
  color: #000;
}
.user_details_with_cycle {
  float: left;
  width: 100%;
  position: relative;
  padding: 35px 0;
}
.user_details_with_cycle img {
  width: 100%;
}
.bike_details_section {
  float: left;
  width: 100%;
}
.bike_details_section h4 {
  color: #004DAC;
  font-family: 'metropolissemi_bold';
  font-size: 20px;
  margin: 0 0 20px;
}
.bike_details_wrapper {
  float: left;
  width: 100%;
  position: relative;
}
.bike_details_wrapper .bike_row {
  float: left;
  width: 100%;
  display: flex;
  column-gap: 10px;
}
.bike_detils_blog {
  float: left;
  width: 100%;
  margin: 0 0 17px;
  border-left: 3px solid #004DAC;
  padding: 5px 0px 5px 10px;
}
.bike_detils_blog span {
  float: left;
  width: 100%;
  font-size: 13px;
  color: #000;
}
.bike_detils_blog div {
  float: left;
  width: 100%;
  font-family: 'metropolissemi_bold';
  font-size: 19px;
  line-height: normal;
  word-break: break-word;
}
.cycle_wraps_main {
  float: left;
  width: 100%;
  background-color: #F9F9F9;
  padding: 20px 25px;
  position: relative;
  border-radius: 8px;
  box-shadow: 6px 6px 12px -5px #ccc;
  -webkit-box-shadow: 6px 6px 12px -5px #ccc;
  -moz-box-shadow: 6px 6px 12px -5px #ccc;
}
.cycle_detail_image {
  margin: -113px 0 0 !important;
}
.cycle_wraps_main_head {
  float: left;
  width: 66%;
  background-color: #F9F9F9;
  padding: 9px 25px;
  position: relative;
  border-radius: 8px;
  box-shadow: 6px 6px 12px -5px #ccc;
  -webkit-box-shadow: 6px 6px 12px -5px #ccc;
  -moz-box-shadow: 6px 6px 12px -5px #ccc;
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin: 0 0 23px;
}
.cycle_wraps_main_head h4 {
  font-family: 'metropolismedium';
  margin: 0px;
  font-size: 20px;
  float: left;
  width: auto;
}
.cycle_wraps_main_head .cycel_user_deta {
  float: left;
  width: auto;
  border-left: 3px solid #004DAC;
  padding: 2px 0px 2px 10px;
}
.cycle_wraps_main_head .cycel_user_deta div {
  line-height: 17px;
  font-size: 13px;
}
.cycle_detail_image {
  margin-left: auto !important;
}
.cycle_wraps_main_wrapper h4 {
  font-family: 'metropolismedium';
  float: left;
  width: auto;
  color: #004DAC;
  font-family: 'metropolissemi_bold';
  font-size: 20px;
  margin: 0 0 20px;
}
.cycle_with_score {
  float: left;
  width: 100%;
  position: relative;
}
.cycle_with_score span {
  float: left;
  width: auto;
  font-size: 9px;
  position: absolute;
  color: #fff;
  font-family: 'metropolissemi_bold';
  line-height: normal;
}
.cycle_with_score span small {
  font-weight: 100;
  font-size: 6px;
}
.cycle_with_score .frame_score {
  left: 112px;
  top: 21.4%;
  font-weight: 500;
}
.cycle_with_score .rear_tire_score {
  left: 34.4px;
  top: 35.4%;
  font-weight: 500;
}
.cycle_with_score .breaks_score {
  left: 11.8px;
  top: 62.2%;
  font-weight: 500;
}
.cycle_with_score .gears_score {
  left: 22px;
  top: 71%;
  font-weight: 500;
}
.cycle_with_score .suspension_score {
  right: 33px;
  top: 33%;
  font-weight: 500;
}
.cycle_with_score .front_tire_score {
  right: 5.1px;
  top: 79.6%;
  font-weight: 500;
}
.cycle_with_score .frame_score.active {
  left: 114px;
}
.cycle_with_score .rear_tire_score.active {
  left: 35.4px;
}
.cycle_with_score .breaks_score.active {
  left: 12.8px;
}
.cycle_with_score .gears_score.active {
  left: 24px;
}
.cycle_with_score .suspension_score.active {
  right: 35px;
}
.cycle_with_score .front_tire_score.active {
  right: 8.1px;
}
.bike_condtions_gallery {
  float: left;
  width: 100%;
  padding: 55px 0 10px;
}
.bike_condtions_gallery .bike_gallery {
  float: left;
  width: 100%;
  margin-bottom: 35px;
}
.bike_condtions_gallery .col-md-6:last-child .bike_gallery, 
.bike_condtions_gallery .col-md-6:nth-last-child(2) .bike_gallery {
  margin-bottom: 10px;
}
.images_gallery_dummy {
  width: 100%;
  height: 116px;
  border-radius: 6px;
  float: left;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 1px;
  font-style: italic;
  padding: 0 10px;  
  text-align: center;
  border: 1px solid #ddd;
}
.images_gallery_dummy i {
  font-size: 19px;
}
.images_gallery_dummy span {
  font-size: 9px;
}
.images_gallery_wrapper {
  float: left;
  width: 100%;
}
.images_gallery_wrapper img {
  width: 100%;
  height: 116px;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}
.bike_gallery h5 {
  float: left;
  width: 100%;
  font-family: 'metropolismedium';
  font-size: 18px;
  margin: 0 0 12px;
  position: relative;
}
.speedometer_pdf_wraps_wrapper {
  float: left;
  width: 100%;
}
.speedometer_pdf_wraps {
  float: left;
  width: 330px;
  position: relative;
}
.speedometer_pdf_wraps .speedometer_img {
  width: 100%;
}
.speedometer_pdf_wraps .speedometre_niddle {
  width: 50px;
  position: absolute;
  left: 27px;
  top: 87px;
  right: 0;
  float: none;
  display: table;
  margin: 0 auto;
  /* transform: rotate(-139deg); */
  transform-origin: 37% 90%;
}
.speedometer_pdf_wraps h1 {
  float: left;
  width: auto;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: table;
  bottom: 40px;
  font-family: 'metropolissemi_bold';
  font-size: 45px;
}
.speedometer_pdf_wraps h2 {
  float: left;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  font-size: 22px;
  text-align: center;
  bottom: 8px;
  font-family: 'metropolismedium';
  margin: 0px;
}
.needle {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  background: red;
  transform-origin: bottom;
  transition: transform 0.5s ease-in-out;
}
.offers_n_bike_sections {
  float: left;
  width: 100%;
  margin: 10px 0 0;
  position: relative;
}
.offer_for_column {
  float: left;
  width: 100%;
  position: relative;
  margin: 0 0 25px;
}
.offers_n_bike_sections .col-md-4:last-child .offer_for_column, 
.offers_n_bike_sections .col-md-4:nth-last-child(2) .offer_for_column {
  margin-bottom: 0px;
}
.offer_for_column_header {
  float: left;
  width: 100%;
  position: relative;
  margin: 0 0 -5px;
  border-left: 3px solid #004DAC;
  padding: 2px 0px 2px 10px;
  line-height: normal;
}
.offer_for_column_header span {
  font-size: 14px;
  line-height: normal;
  color: #333;
}
.offer_for_column_header h5 {
  float: left;
  width: 100%;
  font-family: 'metropolissemi_bold';
  margin: 0px;
  font-size: 17px;
}
.offer_for_column img {
  width: 213px !important;
  height: 160px !important;
  object-fit: contain;
  object-position: left;
}
.evalutions_colus_wrapper {
  float: left;
  width: 100%;
  position: relative;
}

.not_cols_evalutions {
  float: left;
  width: 100%;
  position: relative;
  position: relative;
  font-size: 13px;
}
.not_cols_evalutions span {
  color: #004DAC;
  font-family: 'metropolissemi_bold';
}
.evalutions_colus {
  float: left;
  width: 100%;
  font-size: 13px;
  background-color: #fff;
  padding: 20px 20px;
  max-height: 142px;
  margin-bottom: 20px;
  box-shadow: 6px 6px 12px -5px #ccc;
  -webkit-box-shadow: 6px 6px 12px -5px #ccc;
  -moz-box-shadow: 6px 6px 12px -5px #ccc;
  border-radius: 8px;
}
.evalutions_colus h5 {
  font-size: 14px;
  font-family: 'metropolissemi_bold';
  margin: 0 0 8px;
}
.evalutions_colus p {
  font-size: 12px;
  line-height: 16px;
}
.health_evalutions_colus_wrapper .evalutions_colus {
  max-height: fit-content;
  background-color: transparent;
  box-shadow: none;
  padding: 0px;
  margin-bottom: 10px;
  border-radius: 0px;
}
/* pdf view css */

/* deactivate account css */
.deactivate_account_wrapper {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
}
.deactivate_account_wrapper .main_form_Box {
  float: none;
  margin: 0 auto;
  height: calc(100vh - 30px);
  border-radius: 10px;
  background-color: #fff;
  padding: 0px !important;
  overflow: hidden;
}
.deactivate_account_wrapper .main_from_logo_header {
  float: left;
  width: 100%;
  text-align: center;
  padding: 15px 20px;
  margin: 0 0 20px;
  box-shadow: -2px 1px 6px 0px #ccc;
}
.deactivate_account_wrapper .main_from_logo_header img {
  width: 75px;
}
.deactivate_account_wrapper .main_from_logo_header h4 {
  font-size: 17px;
  margin: 17px 0 0;
}
.deactivate_account_wrapper .loginMainblog h4, .deactivate_account_wrapper .formloginset .form-label,
.deactivate_account_wrapper .loginMainblog.Mainregisterproduct p, .deactivate_account_wrapper  .back_btn {
  color: #333 !important;
}
.deactivate_account_wrapper  .loginMainblog.Mainregisterproduct {
  padding: 15px 20px !important;
}
.deactivate_account_wrapper .faq_logs_wrapper {
  padding: 5px 20px 15px 20px !important;
}
.deactivate_account_wrapper .faq_logs_wrapper .accordion-button {
  padding: 14px 10px;
  font-size: 14px;
}
.deactivate_account_wrapper .faq_logs_wrapper .accordion-body {
  padding: 10px 10px;
  font-size: 14px;
}
/* deactivate account css */


/* media css */
@media only screen and (max-width: 991px) {
  .tabmobile_buttons {
    flex-direction: column;
  }
}
@media only screen and (max-width: 768px) {
  .MainregisterproductWrapper {
    background-position: 70%;
  }
  .cycle_detail_image {
    width: 50% !important;
  }
}
@media only screen and (max-width: 600px) {
  .bike_condtions_gallery .col-md-6.col-sm-6, .speedtom_wrapper .col-md-6.col-sm-6 {
    width: 50%;
  }
  .evalutions_colus_wrapper .col-md-6.col-sm-6 {
    width: 50%;
  }
  .offers_n_bike_sections .col-md-4.col-sm-4 {
    width: 33.33%;
  }
  .cycle_common_Loader .cycle_common_Loader_blog {
    float: left;
    width: 95%;
  }
  .cycle_common_Loader h4 {
    line-height: 18px;
    font-size: 13px;
    margin: 16px 0 0;
  }
  .Toastify__toast-container {
    direction: rtl !important;
    margin: 10px 0px 0 -10px !important;
  }
  .franhcise_header.after_login_franhcise_header .header_logout{
    font-size: 20px;
    position: relative;
    right: 0px;
    top: 0px;
  }
  .franhcise_header.after_login_franhcise_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px 0px 5px;
  }
  .franhcise_header.after_login_franhcise_header .logoheader {
    width: auto;
  }
  .MainregisterproductWrapper {height: 100dvh;overflow: auto;}
  .capture_camera_modal {width: 100% !important;height: 100dvh;}
  .capture_camera_modal video {
    height: calc(100dvh - 165px);
  }
  .TwocolsInOneRow .col-md-6.col-sm-6.col-xs-6 {
    width: 50%;
  }
  .logoheader {padding: 15px 10px;}
  .MainregisterproductWrapper .logoheader img {
      width: 200px;
  }
  .preivew_image {
    width: 100%;
    height: 100%;
  }
  .preivew_image img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
  .Toastify__toast-container {
    width: auto;
  }
  .Toastify__toast-container .Toastify__toast {
    height: auto;
    line-height: normal;
    min-height: fit-content;
    max-height: fit-content;
    width: max-content;
  }
  .Toastify__toast-container .Toastify__toast .Toastify__toast-body {
    padding: 0px;
    margin: 0px;
    font-size: 13px;
  }
  .Toastify__toast-container .Toastify__toast .Toastify__toast-body .Toastify__toast-icon {
    width: auto;
  }
  .Toastify__toast-container .Toastify__toast .Toastify__toast-body svg {
    font-size: 14px;
    height: 14px;
    width: 14px;
  }
  .Toastify__toast-container .Toastify__toast .Toastify__close-button {
    margin-left: 6px;
  }
}
@media only screen and (max-width: 540px) {
  .deactivate_account_wrapper {
    align-items: flex-start;
  }
  .deactivate_account_wrapper .main_form_Box {
    border-radius: 0px;
    height: 100vh !important;
  }
  .Speedometer_wrapper .labels {
    width: 211px;
  }
  .MainregisterproductWrapper {
    padding: 17px 17px 17px 17px;
    background-position: 55% center;
  }
  .main_form_Box {
    width: 100%;
  }
  #permision_Modal_wrapper_main .main_form_Box.permision_Modal_wrapper {
    position: fixed !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 85%;
  }
  .godown-select__menu {
    float: left;
    width: 100% !important;
  }
}
@media only screen and (max-width: 500px) {
  .iamge_boxed{
    display: flex;
  }
  .Image_preview_wrapper {
    padding: 11px 15px 11px 15px;
  }
  .Image_preview_wrapper i {
    right: 9px;
    top: 0px;
    font-size: 30px;
  }
  .Image_preview_wrapper h4 {
    font-size: 18px;
    margin: 0 0 13px;
  }
}
@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    direction: rtl !important;
    margin: 10px 0px 0 -10px !important;
  }
  .camera_loader {
    display: block;
  }
  .camera_loader .spinner-border {
    border-width: 3px;
    margin-right: 10px;
    float: left;
  }
}
@media only screen and (max-width: 440px) {
  .main_form_Box {
    padding: 15px 18px 20px 18px;
  }
  .verfy_Modal_wrapper {
    width: 93%;
  }
  .verfy_Modal_wrapper i {
    font-size: 55px;
    line-height: 55px;
  }
  .verfy_Modal_wrapper h1 {
    font-size: 22px;
  }
  .preivew_image img {
    object-position: top;
  }
  .loginMainblog.Mainregisterproduct {
    width: 100% !important;
  }
  .registerbtn {
    padding: 10px 50px !important;
    width: 90%;
  }
  .welcome_screen h4 {
    font-size: 29px;
  }
  .iamge_boxed li img {
    height: 85px;
  }
}
@media only screen and (max-width: 400px) {
  .imageRefrance_wrapper .formloginset .upload_file_box_wraps:first-child {
    margin-right: 9px;
  }
};